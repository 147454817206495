import styled from "styled-components"

const ColorBar = styled.div`
  background-image: linear-gradient(
    to ${props => (props.reverse ? "left" : "right")},
    ${props => props.theme.rouge},
    ${props => props.theme.goldenDream}
  );
  min-height: 4px;
`

export default ColorBar
