import { Link } from "gatsby"
import { rgba } from "polished"
import React, { useContext, useState } from "react"
import styled from "styled-components"

import arrowIcon from "$assets/arrow-icon.svg"
import { MobileContext } from "$helpers/MobileProvider"

const Arrow = styled.img`
  display: block;
  height: 16px;
  margin: auto;
`

const ArrowContainer = styled.div`
  display: none;

  @media only screen and ${props => props.theme.deviceSizes.tablet} {
    border-left: 1px solid ${props => props.theme.alto};
    box-sizing: border-box;
    display: flex;
    height: 100%;
    width: 48px;
  }
`

const NavDropdownContainer = styled.div`
  position: relative;

  @media only screen and ${props => props.theme.deviceSizes.tablet} {
    border-top: 1px solid ${props => props.theme.alto};
    display: flex;
    height: 38px;
    justify-content: space-between;
  }
`

const NavLink = styled(Link)`
  color: ${props => rgba(props.theme.nero, 0.5)};
  font-family: ${props => props.theme.fontHighlight};
  letter-spacing: 0.5px;
  padding: 10px 16px;
  text-decoration: none;
  text-transform: uppercase;
  transition: color 0.3s;
  width: 100%;

  :hover {
    color: ${props => props.theme.darken(props.theme.nero)};
  }

  @media only screen and ${props => props.theme.deviceSizes.tablet} {
    color: ${props => props.theme.tundora};
  }
`

const NavLinkList = styled.div`
  border-top: 1px solid ${props => props.theme.alto};
  display: flex;
  flex-direction: column;
`

const NavLinkMenu = styled.div`
  background-color: white;
  border-color: ${props => props.theme.alto};
  border-style: none solid solid;
  border-width: 1px;
  display: flex;
  flex-direction: column;
  left: 0px;
  position: absolute;
  top: 28px;
  width: max-content;
  z-index: 2;
`

const NavDropdown = ({ children, text, to }) => {
  const [isShown, setIsShown] = useState(false)
  const toggleIsShown = () => setIsShown(!isShown)

  const { isMobile } = useContext(MobileContext)

  return (
    <>
      <NavDropdownContainer onMouseLeave={() => !isMobile && setIsShown(false)}>
        <NavLink
          activeStyle={!isMobile ? { color: "black" } : {}}
          onMouseEnter={() => !isMobile && setIsShown(true)}
          to={to}
        >
          {text}
        </NavLink>
        <ArrowContainer>
          <Arrow
            onClick={toggleIsShown}
            src={arrowIcon}
            style={isShown ? { transform: "rotate(180deg)" } : {}}
          />
        </ArrowContainer>
        {isShown && !isMobile && <NavLinkMenu>{children}</NavLinkMenu>}
      </NavDropdownContainer>
      {isShown && isMobile && <NavLinkList>{children}</NavLinkList>}
    </>
  )
}

export default NavDropdown
