import styled from "styled-components"

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  width: 80%;

  @media only screen and ${props => props.theme.deviceSizes.tablet} {
    width: 90%;
  }
`

export default Container
