import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import NavDropdown from "./NavDropdown"

const NavDropdownLink = styled(Link)`
  color: ${props => props.theme.tundora};
  outline: none;
  padding: 12px 16px;
  text-decoration: none;
  text-transform: none;

  :hover {
    background-color: ${props => props.theme.alto};
    color: ${props => props.theme.darken(props.theme.tundora)};
  }
`

const NavContactLink = styled(Link)`
  border: solid 1px ${props => props.theme.tundora};
  border-radius: 3px;
  color: ${props => props.theme.tundora};
  font-family: ${props => props.theme.fontHighlight};
  letter-spacing: 0.5px;
  outline: none;
  padding: 8px 16px;
  text-decoration: none;
  transition: 0.3s;

  :hover {
    background-color: ${props => props.theme.tundora};
    color: white;
    cursor: pointer;
  }

  @media only screen and ${props => props.theme.deviceSizes.tablet} {
    border: none;
    border-radius: 0;
    border-top: 1px solid ${props => props.theme.alto};
    padding: 10px 16px;
    text-transform: uppercase;
  }
`

const NavLoginLink = styled.a`
  color: ${props => props.theme.tundora};
  font-family: ${props => props.theme.fontHighlight};
  letter-spacing: 0.5px;
  padding: 0 16px;
  text-decoration: none;
  text-transform: uppercase;

  :hover {
    text-decoration: underline;
  }

  @media only screen and ${props => props.theme.deviceSizes.tablet} {
    border-top: 1px solid ${props => props.theme.alto};
    padding: 10px 16px;
    text-transform: uppercase;
  }
`

const NavLinks = () => (
  <>
    <NavDropdown text="About" to="/about/">
      <NavDropdownLink to="/about/#our-story">Our Story</NavDropdownLink>
      <NavDropdownLink to="/about/#company-values">
        Company Values
      </NavDropdownLink>
      <NavDropdownLink to="/about/#vendor-certification">
        Vendor Certification
      </NavDropdownLink>
    </NavDropdown>
    <NavDropdown text="Services" to="/services/">
      <NavDropdownLink to="/services/#managed-services">
        Managed Services
      </NavDropdownLink>
      <NavDropdownLink to="/services/#procurement-services">
        Procurement Services
      </NavDropdownLink>
      <NavDropdownLink to="/services/#projects-consulting">
        Projects and Consulting
      </NavDropdownLink>
    </NavDropdown>
    <NavDropdown text="Insights" to="/insights/">
      <NavDropdownLink to="/articles/">Articles</NavDropdownLink>
      <NavDropdownLink to="/insights/#resources">Resources</NavDropdownLink>
    </NavDropdown>
    <NavContactLink to="/contact/">Contact</NavContactLink>
    <NavLoginLink
      href="http://support.lns.net.au/Portal"
      rel="noopener noreferrer"
      target="_blank"
    >
      Support Portal
    </NavLoginLink>
  </>
)

export default NavLinks
