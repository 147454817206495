import { Link } from "gatsby"
import React, { useContext, useEffect, useRef, useState } from "react"
import styled from "styled-components"

import logo from "$assets/lns-logo-horizontal.jpg"
import menuIcon from "$assets/menu-icon.svg"
import { MobileContext } from "$helpers/MobileProvider"

import ColorBar from "./ColorBar"
import Container from "./Container"
import NavLinks from "./NavLinks"

const NAV_LOGO_ORIGINAL_HEIGHT = 594
const NAV_LOGO_LEFT_MARGIN = 76

const ColorBarBottom = styled(ColorBar)`
  display: none;

  @media only screen and ${props => props.theme.deviceSizes.tablet} {
    display: block;
  }
`

const ColorBarDivider = styled(ColorBar)`
  @media only screen and ${props => props.theme.deviceSizes.tablet} {
    display: none;
  }
`

const ContactContainer = styled(Container)`
  display: flex;
  justify-content: flex-end;
  margin: 8px auto;

  @media only screen and ${props => props.theme.deviceSizes.tablet} {
    display: none;
  }
`

const ContactLink = styled.a`
  color: ${props => props.theme.nero};
  margin: 0 15px;
  text-decoration: none;
  text-transform: uppercase;

  :hover {
    color: ${props => props.theme.darken(props.theme.nero)};
  }
`

const HeaderWrapper = styled.header`
  overflow: visible;
  z-index: 2;

  @media only screen and ${props => props.theme.deviceSizes.tablet} {
    height: 104px;
  }
`

const HomeLink = styled(Link)`
  text-decoration: none;
`

const NavContactLink = styled(Link)`
  border: solid 1px ${props => props.theme.tundora};
  border-radius: 3px;
  box-sizing: border-box;
  color: ${props => props.theme.tundora};
  font-family: ${props => props.theme.fontHighlight};
  height: 37px;
  letter-spacing: 0.5px;
  margin-right: 16px;
  outline: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.3s;

  :active {
    background-color: ${props => props.theme.tundora};
    color: white;
  }
`

const NavContainer = styled(Container)`
  align-items: center;
  display: flex;
  justify-content: space-between;
`

const NavDesktop = styled.nav`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  text-transform: uppercase;
`

const NavLinkContainer = styled.div`
  display: none;

  @media only screen and ${props => props.theme.deviceSizes.tablet} {
    background-color: white;
    border-bottom: 1px solid ${props => props.theme.alto};
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`

const getNavLogoLeftOffset = height =>
  (NAV_LOGO_LEFT_MARGIN * height) / NAV_LOGO_ORIGINAL_HEIGHT

const NavLogo = styled.img.attrs(() => ({
  desktopHeight: 96,
  mobileHeight: 76,
}))`
  display: block;
  height: ${props => props.desktopHeight}px;
  margin: 12px 0 12px -${props => getNavLogoLeftOffset(props.desktopHeight)}px;

  @media only screen and ${props => props.theme.deviceSizes.tablet} {
    height: ${props => props.mobileHeight}px;
    margin-left: -${props => getNavLogoLeftOffset(props.mobileHeight)}px;
  }
`

const NavMenuIcon = styled.img`
  height: 2.5rem;
  width: 2.5em;
`

const NavMobile = styled.nav`
  align-items: center;
  display: flex;
`

const Header = () => {
  const [isNavMenuOpen, setIsNavMenuOpen] = useState(false)
  const handleNavMenuClick = () => setIsNavMenuOpen(!isNavMenuOpen)

  const { isMobile } = useContext(MobileContext)

  // Get bottom of bottom element to enable 'tap outside to hide'
  const bottomEl = useRef(null)

  const handleTouchOutside = evt =>
    isNavMenuOpen &&
    evt.touches.item(0).pageY >
      bottomEl.current.getBoundingClientRect().bottom &&
    setIsNavMenuOpen(false)

  useEffect(() => {
    document.addEventListener("touchstart", handleTouchOutside)
    return () => document.removeEventListener("touchstart", handleTouchOutside)
  })

  return (
    <HeaderWrapper>
      <ContactContainer>
        <ContactLink as="a" href="tel:1300767428">
          1300 767 428
        </ContactLink>
      </ContactContainer>
      <ColorBarDivider />
      <NavContainer>
        <HomeLink to="/">
          <NavLogo alt="Lotus Network Solutions" src={logo} />
        </HomeLink>
        {isMobile ? (
          <NavMobile>
            {isMobile === "tablet" && (
              <>
                <ContactLink as="a" href="tel:1300767428">
                  1300 767 428
                </ContactLink>
                <NavContactLink to="/contact/">Contact</NavContactLink>
              </>
            )}
            <NavMenuIcon onClick={handleNavMenuClick} src={menuIcon} />
          </NavMobile>
        ) : (
          <NavDesktop>
            <NavLinks />
          </NavDesktop>
        )}
      </NavContainer>
      {isNavMenuOpen && isMobile && (
        <NavLinkContainer>
          <NavLinks />
        </NavLinkContainer>
      )}
      <ColorBarBottom ref={bottomEl} />
    </HeaderWrapper>
  )
}

export default Header
