import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

import ColorBar from "./ColorBar"
import ContactInfo from "./ContactInfo"
import Container from "./Container"

const ContentContainer = styled(Container)`
  margin-top: 2rem;
`

const CopyrightContainer = styled(ContentContainer)`
  color: ${props => props.theme.silverSand};
`

const DesktopBr = styled.br`
  @media only screen and ${props => props.theme.deviceSizes.mobile} {
    display: none;
  }
`

const FooterHeadingLink = styled(Link)`
  color: inherit;
  display: block;
  font-size: 1.2rem;
  margin-bottom: 24px;
  max-width: max-content;
  text-decoration: none;

  @media only screen and ${props => props.theme.deviceSizes.mobile} {
    margin-top: 24px;
  }
`

const FooterLink = styled(Link)`
  color: inherit;
  display: block;
  font-family: ${props => props.theme.fontSecondary};
  margin: 8px 0;
  max-width: max-content;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`

const FooterWrapper = styled.footer`
  box-sizing: border-box;
  margin-top: auto;
  min-height: min-content;
  padding-bottom: 1.5rem;

  @media only screen and ${props => props.theme.deviceSizes.mobile} {
    background-color: ${props => props.theme.tundora};
    color: ${props => props.theme.gallery};
  }
`

const HomeLink = styled(Link)`
  color: inherit;
  display: block;
  font-size: 1.5rem;
  margin-bottom: 16px;
  max-width: max-content;
  text-decoration: none;
`

const LoginLink = styled.a`
  color: inherit;
  display: block;
  font-family: ${props => props.theme.fontSecondary};
  font-size: 1.2rem;
  margin-right: 0.6rem;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`

const LoginLinkContainer = styled.div`
  align-items: center;
  display: flex;
`

const PrivacyLink = styled(Link)`
  color: inherit;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`

const Section = styled.div`
  margin: 0 1rem;

  :first-child {
    margin-left: 0;
  }

  :last-child {
    margin-right: 0;
  }

  @media only screen and ${props => props.theme.deviceSizes.mobile} {
    margin: 0;
  }
`

const Sections = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and ${props => props.theme.deviceSizes.mobile} {
    display: block;
  }
`

const Footer = ({ children }) => (
  <FooterWrapper>
    <ColorBar reverse />
    <ContentContainer>
      <Sections>
        {/* <div>
          <HomeLink to="/">Lotus Network Solutions</HomeLink>
          <LoginLink href="http://support.lns.net.au" target="_blank">
            Support Portal - Client Login
          </LoginLink>
        </div> */}
        <Section>
          <HomeLink to="/">Lotus Network Solutions</HomeLink>
          <DesktopBr />
          <FooterHeadingLink to="/about/">About Us</FooterHeadingLink>
          <FooterLink to="/about/#our-story">Our Story</FooterLink>
          <FooterLink to="/about/#company-values">Company Values</FooterLink>
          <FooterLink to="/about/#vendor-certification">
            Vendor Certification
          </FooterLink>
        </Section>
        <Section>
          <FooterHeadingLink to="/services/">Services</FooterHeadingLink>
          <FooterLink to="/services/#managed-services">
            Managed Services
          </FooterLink>
          <FooterLink to="/services/#procurement-services">
            Procurement Services
          </FooterLink>
          <FooterLink to="/services/#projects-consulting">
            Projects and Consulting
          </FooterLink>
          <DesktopBr />
          <FooterHeadingLink to="/insights/">Insights</FooterHeadingLink>
          <FooterLink to="/articles/">Articles</FooterLink>
          <FooterLink to="/insights/#resources">Resources</FooterLink>
        </Section>
        <Section>
          <FooterHeadingLink to="/contact/">Contact Us</FooterHeadingLink>
          <ContactInfo lineBreaks />
          <br />
          <LoginLinkContainer>
            <LoginLink
              href="http://support.lns.net.au/Portal"
              rel="noopener noreferrer"
              target="_blank"
            >
              Support Portal <ion-icon name="open-outline"></ion-icon>
            </LoginLink>
          </LoginLinkContainer>
        </Section>
      </Sections>
    </ContentContainer>
    <CopyrightContainer>
      © 2020 Lotus ICT Group Pty Ltd. |{" "}
      <PrivacyLink to="/privacy-policy/">Privacy Policy</PrivacyLink>
      {children && " | "}
      {children}
      <br />
      Any third-party links are not affiliated with Lotus Network Solutions, and
      are provided purely for informative purposes. Logos used are the property
      of their respective owners.
    </CopyrightContainer>
  </FooterWrapper>
)

export default Footer
