import React from "react"
import styled from "styled-components"

const ContactInfoContainer = styled.div`
  ${props => props.containerStyles}
`

const ContactInfoItem = styled.div`
  display: flex;
  margin: 8px 0;
`

const ContactInfoLink = styled.a`
  color: inherit;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`

const ContactInfoText = styled.span`
  font-family: ${props => props.theme.fontBody};
  font-size: ${props => props.fontSize || "1rem"};
  margin-left: ${props => props.fontSize || "1rem"};
  width: max-content;
`

const ContactInfo = ({
  containerStyles,
  fontSize,
  ionIconSize,
  lineBreaks,
}) => (
  <ContactInfoContainer containerStyles={containerStyles}>
    <ContactInfoItem>
      <ion-icon name="location-outline" size={ionIconSize} />
      <ContactInfoText fontSize={fontSize}>
        <ContactInfoLink href="https://www.google.com/maps/place/Lotus+Network+Solutions/@-33.9510627,151.1384877,19.03z/data=!4m13!1m7!3m6!1s0x6b12ba005d878025:0x7438b13981fd8e4b!2s1%2F454+Princes+Hwy,+Rockdale+NSW+2216!3b1!8m2!3d-33.9509831!4d151.1388518!3m4!1s0x6b12bb6efc14431d:0xc1d620b1aa58b460!8m2!3d-33.9509831!4d151.1388518">
          Suite 1, 454 Princes Highway{lineBreaks ? <br /> : ", "}Rockdale NSW
          2216
        </ContactInfoLink>
      </ContactInfoText>
    </ContactInfoItem>
    <ContactInfoItem>
      <ion-icon name="call-outline" size={ionIconSize} />
      <ContactInfoText fontSize={fontSize}>
        <ContactInfoLink href="tel:1300767428">1300 767 428</ContactInfoLink>
      </ContactInfoText>
    </ContactInfoItem>
    <ContactInfoItem>
      <ion-icon name="mail-outline" size={ionIconSize} />
      <ContactInfoText fontSize={fontSize}>
        <ContactInfoLink href="mailto:info@lns.net.au">
          info@LNS.NET.AU
        </ContactInfoLink>
      </ContactInfoText>
    </ContactInfoItem>
    <ContactInfoItem>
      <ion-icon name="mail-outline" size={ionIconSize} />
      <ContactInfoText fontSize={fontSize}>
        P.O. Box 452{lineBreaks ? <br /> : ", "}Rockdale NSW 2216
      </ContactInfoText>
    </ContactInfoItem>
  </ContactInfoContainer>
)

export default ContactInfo
